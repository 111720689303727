import { createRouter, createWebHashHistory, RouteLocationNormalized, RouteRecordRaw, createWebHistory } from 'vue-router'
import mapRule from './config'
import navigatorType from '@/utils/browser'

const isPc = navigatorType()
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      name: 'title',
      keepAlive: true
    },
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('../pages/Index.vue'),
        meta: {
          hideBack: true,
          keepAlive: true,
          footActive: "first",
          // title:"国家标准物质网 国家标准物质中心 北纳生物",
          title: "北纳生物|微生物菌种|细胞库",
          content:{
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        },
      },
      {
        path: '/interest',
        name: 'Interest',
        component: () => import('../pages/Interest.vue'),
        meta: {
          footActive: "first",
          // title:"感兴趣的人-个人主页-北纳生物-国家标准物质网",
          title: "感兴趣的人-个人主页-北纳生物",
          content:{
            keywords:"个人中心,关注,北纳生物",
            description:"北纳生物个人中心是用户在交流区的个人主页展示，可供用户查看帖子、课堂、文库、圈子及其他版块内容，并包含用户的关注、粉丝、积分、获赞、勋章等详情。客服中心:400-6699-833",
          }
        },
      },
        {
            path: '/activity',
            name: 'Activity',
            component: () => import('../pages/Activity.vue'),
            meta: {
                keepAlive: true,
                searchType: 'news',
                footActive: "first",
                // title:"公司动态-北纳生物-国家标准物质网",
                title: "限时活动-北纳生物",
                content:{
                    keywords:"细胞培养技巧,菌株保藏方法,ATCC,菌株,细胞,质粒,培养基,招商信息,新品上市,北纳生物",
                    description:"北纳生物官网为您每日更新生物研发领域新闻动态,最及时的国内外最新新闻信息,新闻内容包罗万象:企业动态、行业新闻、技术前沿、培养技巧、保藏方法、新品上市、促销活动及招商信息等"
                }
            },
        },
        {
            path: '/activitydetail',
            name: 'ActivityDetail',
            component: () => import('../pages/ActivityDetail.vue'),
            meta: {
                hideFooter: true,
                searchType: 'news',
                // title:"新闻详情页-北纳生物-国家标准物质网",
                title: "新闻标题-北纳生物",
                content:{
                    keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
                    description:"北纳生物公司动态为您提供新品上市,细胞培养技巧,实用的菌株保藏方法,企业公益活动,企业招商及火爆的促销活动等各类新闻消息,方便您及时查询"
                }
            },
        },
      {
        path: '/news',
        name: 'News',
        component: () => import('../pages/News.vue'),
        meta: {
          keepAlive: true,
          searchType: 'news',
          footActive: "first",
          // title:"公司动态-北纳生物-国家标准物质网",
          title: "新闻中心-北纳生物",
          content:{
            keywords:"细胞培养技巧,菌株保藏方法,ATCC,菌株,细胞,质粒,培养基,招商信息,新品上市,北纳生物",
            description:"北纳生物官网为您每日更新生物研发领域新闻动态,最及时的国内外最新新闻信息,新闻内容包罗万象:企业动态、行业新闻、技术前沿、培养技巧、保藏方法、新品上市、促销活动及招商信息等"
          }
        },
      },
      {
        path: '/newsdetail',
        name: 'NewsDetail',
        component: () => import('../pages/NewsDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'news',
          // title:"新闻详情页-北纳生物-国家标准物质网",
          title: "新闻标题-北纳生物",
          content:{
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物公司动态为您提供新品上市,细胞培养技巧,实用的菌株保藏方法,企业公益活动,企业招商及火爆的促销活动等各类新闻消息,方便您及时查询"
          }
        },
      },
      {
        path: '/previewdetail',
        name: 'previewDetail',
        component: () => import('../pages/previewDetail.vue'),
        meta: {
          hideFooter: true,
          // searchType: 'news',
          // title:"新闻详情页-北纳生物-国家标准物质网",
          title: "新闻标题-北纳生物",
          content:{
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物公司动态为您提供新品上市,细胞培养技巧,实用的菌株保藏方法,企业公益活动,企业招商及火爆的促销活动等各类新闻消息,方便您及时查询"
          }
        },
      },
      {
        path: '/library',
        name: 'Library',
        component: () => import('../pages/Library.vue'),
        meta: {
          hideFooter: true,
          title: "文库页面-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/docDetail',
        name: 'DocDetail',
        component: () => import('../pages/DocDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'topic',
          // title:"文库详情页-北纳生物-国家标准物质网",
          title: "文库标题-北纳生物",
          content:{
            keywords:"生物技术文库,细胞技术文献,微生物菌种技术文献,论文下载,学习,查阅,北纳生物",
            description:"在线互动式文档分享平台,在这里,您可以和千万网友分享生物研发相关技术文献,全文阅读其他用户的文档,同时上传文献也可以获取相应积分兑换礼品哦!",
          }
        },
      },
      {
        path: '/forget',
        name: 'Forget',
        component: () => import('../pages/Forget.vue'),
        meta: {
          // title:"密码更换-北纳生物-国家标准物质网",
          title: "密码更换-北纳生物",
          content: {
            keywords: "微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description: "北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        },
      },

      {
        path: '/contact',
        name: 'About',
        component: () => import('../pages/About.vue'),
        meta: {
          footActive: "first",
          // title:"关于北纳生物 国家标准物质网 北纳生物国家标准物质研制单位",
          title: "关于我们-北纳生物",
          content:{
            keywords:"联系我们,公司简介,业务电话,微生物菌种,科研细胞,质粒,感受态细胞,生物技术,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库,微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求！",
          }
        },
      },
      {
        path: '/bbs',
        name: 'Topic',
        component: () => import('../pages/Topic.vue'),
        meta: {
          searchType: 'topic',
          footActive: "first",
          // title:"发现动态-国家标准物质网",
          title: "发现动态-北纳生物",
          content:{
            keywords:"生物研发,技术交流,专家答疑,微生物知识,圈子,问答,文库,课堂,生物科技,北纳生物",
            description:"国内权威的生物研发综合交流区,您可在这里畅所欲言分享您的经验,同时也可以提出各种生物技术相关的问题,相信可以全方位的解决您的各种疑问和需求",
          }
        }
      },
      {
        path: '/bbsdetail',
        name: 'TopicDetail',
        component: () => import('../pages/TopicDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'topic',
          // title:"帖子详情页-北纳生物-国家标准物质网",
          title: "帖子标题-北纳生物",
          content:{
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        },
      },
      {
        path: '/postTopic',
        name: 'PostTopic',
        component: () => import('../pages/PostTopic.vue'),
        meta: {
          hideFooter: true,
          searchType: 'topic',
          requireAuth: true,
          // title:"发现动态-国家标准物质网",
          title: "发现动态-北纳生物",
          content:{
            keywords:"生物研发,技术交流,专家答疑,微生物知识,圈子,问答,文库,课堂,生物科技,北纳生物",
            description:"国内权威的生物研发综合交流区,您可在这里畅所欲言分享您的经验,同时也可以提出各种生物技术相关的问题,相信可以全方位的解决您的各种疑问和需求",
          }
        },
      },
      {
        path: '/productCategory',
        name: 'ProductCategory',
        meta: {
          footActive: "second",
          title: "标准物质目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,北纳生物",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/ProductCategory.vue'),
      },
      {
        path: '/product',
        name: 'product',
        meta: {
          name: 'product',
          keepAlive: true,
          searchType: 'product',
          footActive: "second",
          title: "标准物质目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,北纳生物",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/Product.vue'),
      },
      {
        path: '/extract',
        name: 'Extract',
        meta: {
          hideFooter: true,
          title: "生物制品目录-全部分类",
          content: {
            keywords: "细胞服务,菌种服务,微生物质控品,培养基,技术服务",
            description: "北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产为一体的生物高科技企业,设有细胞库,微生物菌种库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求！",
          }
        },
        component: () => import('../pages/Extract.vue'),
      },
      {
        path: '/bnccCategory',
        name: 'BnccCategory',
        meta: {
          footActive: "second",
          title: "生物制品目录-全部分类",
          content: {
            keywords: "细胞服务,菌种服务,微生物质控品,培养基,技术服务",
            description: "北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产为一体的生物高科技企业,设有细胞库,微生物菌种库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求！",
          }
        },
        component: () => import('../pages/BnccCategory.vue'),
      },
      {
        path: '/bnccProduct',
        name: 'bnccProduct',
        meta: {
          name: 'bnccProduct',
          keepAlive: true,
          searchType: 'bnccProduct',
          footActive: "second",
          title: "生物制品目录-全部分类",
          content: {
            keywords: "细胞服务,菌种服务,微生物质控品,质粒,培养基，基因组DNA,技术服务",
            description: "北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        },
        component: () => import('../pages/BnccProduct.vue'),
      },
      {
        path: '/bncc',
        name: 'Bncc',
        meta: {
          name: 'bncc',
          keepAlive: true,
          searchType: 'bncc',
          footActive: "second",
          title:"微生物菌种，细胞目录-全部分类",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
        component: () => import('../pages/Bncc.vue'),
      },
      {
        path: '/sss/:id',
        name: 'productdetail',
        component: () => import('../pages/ProductDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'product',
          // title:"产品详情页-北纳生物-国家标准物质网",
          title: "产品中文名-北纳生物",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制11大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/:id',
        name: 'bnccdetail',
        component: () => import('../pages/BnccDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-北纳生物-国家标准物质网",
          title: "产品名称-北纳生物",
          content:{
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/b/strAppraisal',
        name: 'strAppraisal',
        component: () => import('../pages/StrAppraisal.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-北纳生物",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/b/mycoplasma',
        name: 'mycoplasma',
        component: () => import('../pages/Mycoplasma.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-北纳生物",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      // 菌种技术服务页面
      {
        path: '/b/identiFication',
        name: 'identiFication',
        component: () => import('../pages/identiFication.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "技术服务-菌种技术服务-菌种鉴定",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/b/preservation',
        name: 'preservation',
        component: () => import('../pages/preservation.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "技术服务-菌种技术服务-菌种代保藏",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/b/subculTure',
        name: 'subculTure',
        component: () => import('../pages/subculTure.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "技术服务-菌种技术服务-菌种代培养",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },

      {
        path: '/b/detection',
        name: 'detection',
        component: () => import('../pages/detection.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "技术服务-菌种技术服务-菌种检测",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/b/digitalpcr',
        name: 'digitalpcr',
        component: () => import('../pages/digitalpcr.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "技术服务-菌种技术服务-数字PCR定值服务",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/b/dnaExtraction',
        name: 'dnaExtraction',
        component: () => import('../pages/DnaExtraction.vue'),
        meta: {
          hideFooter: true,
          searchType: 'bncc',
          // title:"菌种细胞详情页-伟业计量-国家标准物质网",
          title: "菌种细胞中文名-北纳生物",
          content: {
            keywords:"细胞服务,菌种服务,技术服务,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/standard',
        name: 'standard',
        meta: {
          name: 'standard',
          keepAlive: true,
          searchType: 'standard',
          footActive: "second",
          title: "标准目录-全部分类",
          content: {
            keywords: "标准物质,对照品,标准品,国家标准物质中心,北纳生物",
            description: "国内最大的标准物质一站式采购平台,资质齐全,产品高达20余万种,畅销全国各地 发货快,价格低,一对一专属服务,售后有保障,欢迎点击在线客服咨询订购!",
          }
        },
        component: () => import('../pages/Standard.vue')
      },
      {
        path: '/customize',
        name: 'customize',
        meta: {
          name: 'customize',
          hideFooter: true,
          searchType: 'product',
          // title:"定制产品-标准物质-国家标准物质网",
          title: "定制产品-北纳生物",
          content: {
            keywords: "定制服务,按需定制,标准物质,单标,混标,国家标准物质",
            description: "北纳生物拥有国家标准物质定级证书,并获得质检总局授权,是国家标准物质指定生产单位,可提供各种不同浓度的单标及混标定制服务,也可按需定制.专业权威,品质保证,服务一流,客服中心:400-999-3855",
          }
        },
        component: () => import('../pages/Customize.vue')
      },
      {
        path: '/s/:id/:classId',
        name: 'standarddetail',
        component: () => import('../pages/StandardDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'standard',
          // title:"标准详情页-北纳生物-国家标准物质网",
          title: "标准详情页-北纳生物",
          content: {
            keywords: "国家标准物质中心,国家标准物质网,标准物质,中检所,中检所对照品,标准品,中国标准物质网,标准物质中心,国家标准物质,标准物质网",
            description: "国家标准物质网专业提供标准物质,标准品,对照品,试剂与耗材等20余万种,独立研制11大系列国家标准物质,提供各种单标及混标定制,标准查询,标准图书和计量交流等综合服务,一站式满足您的需求",
          }
        }
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('../pages/Order.vue'),
        meta: {
          footActive: "third",
          // title:"订单管理-北纳生物-国家标准物质网",
          title: "订单管理-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/invoice',
        name: 'Invoice',
        component: () => import('../pages/Invoice.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的发票-北纳生物-国家标准物质中心",
          title: "我的发票-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/intergral',
        name: 'Intergral',
        component: () => import('../pages/Intergral.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"积分兑换-北纳生物-国家标准物质中心",
          title: "积分兑换-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/extractinter',
        name: 'Extractinter',
        component: () => import('../pages/Extractinter.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"积分兑换-北纳生物-国家标准物质中心",
          title: "积分兑换-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/exchange',
        name: 'Exchange',
        component: () => import('../pages/Exchange.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          // title:"积分兑换-北纳生物-国家标准物质中心",
          title: "积分兑换-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
      },
      {
        path: '/exchangeDetail',
        name: 'ExchangeDetail',
        component: () => import('../pages/ExchangeDetail.vue'),
        meta: {
          requireAuth: true,
          // title:"积分兑换-北纳生物-国家标准物质中心",
          title: "积分兑换-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
      },


      {
        path: '/intergralRule',
        name: 'IntergralRule',
        component: () => import('../pages/IntergralRule.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"积分规则-北纳生物-国家标准物质中心",
          title: "积分规则-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
      },

      {
        path: '/bill',
        name: 'Bill',
        component: () => import('../pages/Bill.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的贷款-北纳生物-国家标准物质中心",
          title: "我的货款-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
      },
      {
        path: '/user',
        name: 'UserInfo',
        component: () => import('../pages/UserInfo.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          // title:"会员中心-北纳生物-国家标准物质中心",
          title: "会员中心-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/foot',
        name: 'FootPrint',
        component: () => import('../pages/FootPrint.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的足迹-北纳生物-国家标准物质中心",
          title: "我的足迹-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/message',
        name: 'Message',
        component: () => import('../pages/Message.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的消息-北纳生物-国家标准物质中心",
          title: "我的消息-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/attention',
        name: 'Attention',
        component: () => import('../pages/Attention.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的关注-北纳生物-国家标准物质中心",
          title: "我的关注-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/unsubscribe',
        name: 'Unsubscribe',
        component: () => import('../pages/Unsubscribe.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"关注用户-北纳生物-国家标准物质中心",
          title: "关注用户-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/collection',
        name: 'Collection',
        component: () => import('../pages/Collection.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的收藏-北纳生物-国家标准物质中心",
          title: "我的收藏-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('../pages/Account.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的账户-北纳生物-国家标准物质中心",
          title: "我的账户-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/changeMobile',
        name: 'ChangeMobile',
        component: () => import('../pages/ChangeMobile.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"修改手机号-北纳生物-国家标准物质中心",
          title: "修改手机号-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/changePwd',
        name: 'ChangePwd',
        component: () => import('../pages/ChangePwd.vue'),
        meta: {
          footActive: "third",
          hideFooter: true,
          // title:"修改密码-北纳生物-国家标准物质中心",
          title: "修改密码-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/prize',
        name: 'Prize',
        component: () => import('../pages/Prize.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的奖项-北纳生物-国家标准物质中心",
          title: "我的奖项-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/suggest',
        name: 'Suggest',
        component: () => import('../pages/Suggest.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的建议-北纳生物-国家标准物质中心",
          title: "我的建议-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/shopCar',
        name: 'ShopCar',
        component: () => import('../pages/ShopCar.vue'),
        meta: {
          footActive: "third",
          // title:"购物车-北纳生物-国家标准物质中心",
          title: "购物车-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/coupon',
        name: 'Coupon',
        component: () => import('../pages/Coupon.vue'),
        meta: {
          title: "优惠券-北纳生物",
          footActive: "third",
          content: {
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/myInvitation',
        name: 'myInvitation',
        component: () => import('../pages/myInvitation.vue'),
        meta: {
          title: "我的邀请-北纳生物",
          footActive: "third",
          content: {
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/Introduction',
        name: 'Introduction',
        component: () => import('../pages/Introduction.vue'),
        meta: {
          title: "我的邀请-北纳生物",
          footActive: "third",
          content: {
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/Invitation',
        name: 'Invitation',
        component: () => import('../pages/Invitation.vue'),
        meta: {
          title: "邀请注册-北纳生物",
          footActive: "first",
          hideFooter: true,
          hideBack: true,
          content: {
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/anniversarys',
        name: 'Anniversary2022',
        component: () => import('../operation/Anniversary2022.vue'),
        meta: {
          hideFooter: true,
          title: "开工活动——幸运大抽奖-活动-BNCC",
          content: {
            keywords:"开工活动，好礼送不停,活动，生物制品，微生物菌种，科研细胞，BNCC",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/workStart2024',
        name: 'workStart2024',
        component: () => import('../operation/workStart2024.vue'),
        meta: {
          hideFooter: true,
          title: "开工活动——幸运大抽奖-活动-BNCC",
          content: {
            keywords:"开工活动，好礼送不停,活动，生物制品，微生物菌种，科研细胞，BNCC",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/workStart2025',
        name: 'workStart2025',
        component: () => import('../operation/workStart2025.vue'),
        meta: {
          hideFooter: true,
          title: "开工活动——幸运大抽奖-活动-BNCC",
          content: {
            keywords:"开工活动，好礼送不停,活动，生物制品，微生物菌种，科研细胞，BNCC",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/workBncc2024',
        name: 'workBncc2024',
        component: () => import('../operation/workBncc2024.vue'),
        meta: {
          hideFooter: true,
          title: "幸运大抽奖-活动-BNCC",
          content: {
            keywords:"好礼送不停,活动，生物制品，微生物菌种，科研细胞，BNCC",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/exhibition',
        name: 'Exhibition2023',
        component: () => import('../operation/Exhibition2023.vue'),
        meta: {
          hideFooter: true,
          title: "扫码关注送红包-活动-BNCC",
          content: {
            keywords:"扫码关注送红包，好礼送不停,活动，生物制品，微生物菌种，科研细胞，BNCC",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/submitOrder',
        name: 'SubmitOrder',
        component: () => import('../pages/SubmitOrder.vue'),
        meta: {
          Authentication: true,  // 需要登录认证
          hideFooter: true,
          // title:"提交订单-北纳生物-国家标准物质中心",
          title: "提交订单-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/updateAddressOfOrder',
        name: 'updateAddressOfOrder',
        component: () => import('../pages/UpdateAddressOfNeiOrder.vue'),
        meta: {
          Authentication: true,  // 需要登录认证
          hideFooter: true,
          title: "地址修改-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/openSearch',
        name: 'OpenSearch',
        component: () => import('../pages/OpenSearch.vue'),
        meta: {
          footActive: "third",
          // title:"搜索-北纳生物-国家标准物质中心",
          title: "搜索-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/personal',
        name: 'Personal',
        component: () => import('../pages/Personal.vue'),
        meta: {
          footActive: "third",
          // title:"个人主页-北纳生物-国家标准物质中心",
          title: "个人主页-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/signIn',
        name: 'signIn',
        component: () => import('../pages/SignIn.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"签到界面-北纳生物-国家标准物质中心",
          title: "签到-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/course',
        name: 'Course',
        component: () => import('../pages/Course.vue'),
        meta: {
          searchType: 'course',
          keepAlive: true,
          footActive: "four",
          // title:"培训中心-北纳生物-国家标准物质中心",
          title: "培训中心-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            // description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
            description:"大额红包雨！超值优惠券！BNCC线上研讨会每周四上午9:30准时直播，欢迎持续关注",
          }
        },
      },
      {
        path: '/courseCommentDetail',
        name: 'CourseCommentDetail',
        component: () => import('../pages/CourseCommentDetail.vue'),
        meta: {
          searchType: 'course',
          hideFooter: true,
          // title:"课堂评论详情页-伟业计量-国家标准物质中心",
          title: "课堂评论-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
      },
      {
        path: '/topicCommentDetail',
        name: 'TopicCommentDetail',
        component: () => import('../pages/TopicCommentDetail.vue'),
        meta: {
          searchType: 'topic',
          hideFooter: true,
          // title:"帖子评论详情页-伟业计量-国家标准物质中心",
          title: "帖子评论-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        },
      },
      {
        path: '/videodetail',
        name: 'videodetail',
        component: () => import('../pages/VideoDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          // title:"课堂详情页-伟业计量-国家标准物质网",
          title: "课堂详情-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            // description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
            description:"大额红包雨！超值优惠券！BNCC线上研讨会每周四上午9:30准时直播，欢迎持续关注",
          }
        }
      },
      {
        path: '/livedetail',
        name: 'livedetail',
        component: () => import('../pages/LiveDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          title: "直播详情-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            // description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
            description:"大额红包雨！超值优惠券！BNCC线上研讨会每周四上午9:30准时直播，欢迎持续关注",
          }
        }
      },
      {
        path: '/videoRealtime',
        name: 'videoRealtime',
        component: () => import('../pages/VideoRealtime.vue'),
        meta: {
          hideFooter: true,
          searchType: 'course',
          footActive: "four",
          title: "直播详情-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            // description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
            description:"大额红包雨！超值优惠券！BNCC线上研讨会每周四上午9:30准时直播，欢迎持续关注",
          }
        }
      },
      {
        //nei
        path: '/addressOfUser',
        name: 'AddressOfUser',
        component: () => import('../pages/AddressOfNei.vue'),
        meta: {
          hideFooter: true,
          Authentication: true,
          footActive: "third",
          requireAuth: true,
          // title:"我的地址-伟业计量-国家标准物质中心",
          title: "我的地址-北纳生物",
          content: {
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        //nei
        path: '/addAddressOfUser',
        name: 'addAddressOfUser',
        component: () => import('../pages/AddAddressOfNei.vue'),
        meta: {
          hideFooter: true,
          footActive: "third",
          requireAuth: true,
          // title:"添加地址-伟业计量-国家标准物质中心",
          title: "添加地址-北纳生物",
          content: {
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/address',
        name: 'Address',
        component: () => import('../pages/Address.vue'),
        meta: {
          hideFooter: true,
          Authentication: true,
          footActive: "third",
          requireAuth: true,
          title: "我的地址-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/addAddress',
        name: 'addAddress',
        component: () => import('../pages/AddAddress.vue'),
        meta: {
          hideFooter: true,
          footActive: "third",
          requireAuth: true,
          title: "添加地址-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('../pages/Chat.vue'),
        meta: {
          hideFooter: true,
          title: "私信页面-北纳生物",
          content:{
            keywords:"细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description:"北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/orderDetail',
        name: 'OrderDetail',
        component: () => import('../pages/OrderDetail.vue'),
        meta: {
          // title:"订单详情页-伟业计量-国家标准物质中心",
          title: "订单详情页-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/virtuallyInfo',
        name: 'virtuallyInfo',
        component: () => import('../pages/VirtuallyInfo.vue'),
        meta: {
          title: "购物车数据合并-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/neiOrderDetail',
        name: 'NeiOrderDetail',
        component: () => import('../pages/NeiOrderDetail.vue'),
        meta: {
          title: "订单详情页-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login.vue'),
        meta: {
          hideFooter: true,
          title: "登录界面-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/initTel',
        name: 'InitTel',
        component: () => import('../pages/InitTel.vue'),
        meta: {
          hideFooter: true,
          title: "绑定手机号-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/setPassword',
        name: 'SetPassword',
        component: () => import('../pages/SetPassword.vue'),
        meta: {
          hideFooter: true,
          title: "设置密码-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/applyInvoice',
        name: 'ApplyInvoice',
        component: () => import('../pages/ApplyInvoice.vue'),
        meta: {
          hideFooter: true,
          title: "申请发票-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/lottery',
        name: 'Lottery',
        component: () => import('../pages/Lottery.vue'),
        meta: {
          hideFooter: true,
          // title:"大抽奖-伟业计量-国家标准物质中心",
          title: "大抽奖-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/wechatred',
        name: 'WechatRed',
        component: () => import('../pages/WechatRed.vue'),
        meta: {
          hideFooter: true,
          title: "手机下单抽红包-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/startJob',
        name: 'StartJob',
        component: () => import('../operation/StartJob.vue'),
        meta: {
          hideFooter: true,
          // title:"试抽-伟业计量2021开工有礼-伟业计量-国家标准物质中心",
          title: "试抽-伟业计量2021开工有礼-伟业计量",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/startJobReal',
        name: 'StartJobReal',
        component: () => import('../operation/StartJobReal.vue'),
        meta: {
          hideFooter: true,
          // title:"伟业计量2021开工有礼-伟业计量-国家标准物质中心",
          title: "伟业计量2021开工有礼-伟业计量",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/startGift',
        name: 'StartGift',
        component: () => import('../operation/StartGift.vue'),
        meta: {
          hideFooter: true,
          title: "北纳生物2022开工有礼-北纳生物",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/vote',
        name: 'VoteTest',
        component: () => import('../vote/Vote.vue'),
        meta: {
          hideFooter: true,
          title: "餐厅投票",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/voteView',
        name: 'VoteView',
        component: () => import('../vote/VoteView.vue'),
        meta: {
          hideFooter: true,
          title: "餐厅投票",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/staffVote',
        name: 'yearVote',
        component: () => import('../yearVote/yearVote.vue'),
        meta: {
          hideFooter: true,
          title: "2024年度员工投票",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/staffVoteMain',
        name: 'yearVotemain',
        component: () => import('../yearVote/yearVoteMain.vue'),
        meta: {
          hideFooter: true,
          title: "2024年度员工投票",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/exhibitionCollectInfo',
        name: 'exhibitionCollectInfo',
        component: () => import('../operation/ExhibitionCollectInfo.vue'),
        meta: {
          hideFooter: true,
          title: "BNCC展会扫码关注送礼品",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/bnccTurntable',
        name: 'bnccTurntable',
        component: () => import('../operation/BnccTurntable.vue'),
        meta: {
          hideFooter: true,
          title: "BNCC展会扫码关注送礼品",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/staffVoteView',
        name: 'yearVoteView',
        component: () => import('../yearVote/yearVoteView.vue'),
        meta: {
          hideFooter: true,
          title: "投票结果",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/staffVoteMainView',
        name: 'yearVoteMainView',
        component: () => import('../yearVote/yearVoteMainView.vue'),
        meta: {
          hideFooter: true,
          title: "投票结果",
          content: {
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物是一家专注于细胞、微生物菌种领域,集研发、生产和销售为一体的生物高科技企业,设有细胞库、微生物菌种保藏库,细胞培养、菌种培养、分子生物学实验等实验室,全方位满足您的需求",
          }
        }
      },
      {
        path: '/videored',
        name: 'VideoRed',
        component: () => import('../pages/VideoRed.vue'),
        meta: {
          hideFooter: true,
          title: "直播红包-北纳生物",
          content: {
            keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
            description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
          }
        }
      },
      {
        path: '/CourseWX',
        name: 'CourseWX',
        component: () => import('../pages/CourseWX.vue'),
        meta: {
          hideFooter: true,
        }
      },
      {
        path: '/testpic',
        name: 'TestPic',
        component: () => import('../pages/TestPic.vue'),
        meta: {
          hideFooter: true,
          hideHeader: true,
          title: "新闻标题-北纳生物",
          content:{
            keywords:"微生物菌种,ATCC细胞,菌种库,ATCC中文网,细胞系,细胞株,培养基,质粒,感受态细胞,北纳生物",
            description:"北纳生物公司动态为您提供新品上市,细胞培养技巧,实用的菌株保藏方法,企业公益活动,企业招商及火爆的促销活动等各类新闻消息,方便您及时查询"
          }
        },
      },

    ]
  },

  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/Mine.vue'),
    meta: {
      Authentication: true,  // 需要登录认证
      footActive: "third",
      requireAuth: true,
      title: "会员中心-北纳生物",
      content: {
        keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
        description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
      }
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      footActive: "first",
      title: "搜索界面-北纳生物",
      content: {
        keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
        description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
      }
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      footActive: "first",
      title: "404界面-北纳生物",
      content: {
        keywords: "细胞库,肿瘤细胞,正常细胞,转化细胞,菌种库,ATCC,标准菌株,菌种购买,微生物菌种,北纳生物",
        description: "北纳生物产品涵盖细胞株,菌株,质粒,病毒等种类产品数十万种,应有尽有,微生物一站式采购平台,方便快捷！",
      }
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../testPage/Index.vue'),
    meta: {
      footActive: "first",
      title: "测试页面-伟业计量-国家标准物质中心",
    }
  },
  {
    path: '/testKd',
    name: 'TestKd',
    component: () => import('../testPage/TestKd.vue'),
    meta: {
      footActive: "first",
      title: "测试页面-伟业计量-国家标准物质中心",
    }
  },
  {
    path: '/maintain',
    name: 'Maintain',
    component: () => import('../maintenance/Maintain.vue'),
    meta: {
      footActive: "first",
      title: "系统维护中",
    }
  }

]

function scrollBehavior(to: any, from: any, savedPosition: any) {
  if (savedPosition) {
    return savedPosition
  } else {
    return {
      x: 0,
      y: 0
    }
  }
}


const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior
})

//  映射路由到pc端
function routerMap(to: RouteLocationNormalized) {
  const name = to.name;
  let path = mapRule[(name as string)];
  const idReg = /\{\{(.*?)\}\}/;
  if (isPc && path) {
    while (idReg.test(path)) {
      const argName = RegExp.$1;
      const id = to.query[argName] || to.params[argName]
      path = path.replace(idReg, id)
    }
    window.location.href = path
  }
}

router.beforeEach((to, from, next) => {
  routerMap(to)
  const href = window.location.href;
  const codeReg = /code=(.*?)&/;
  let code
  if( codeReg.test(href) ){
    code = RegExp.$1
  }
  const token = localStorage.getItem('token') || '';
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head');
    const meta = document.createElement('meta');
    const keywords = document.querySelector('meta[name="keywords"]');
    const description = document.querySelector('meta[name="description"]')
    keywords && keywords.setAttribute('content', to.meta.content.keywords)
    description && description.setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  const urlReg = /\/#(.*)/ig;
  if (href.match(urlReg)) {
    const urlPath = RegExp.$1;
    window.location.href = urlPath;
    return false
  }
  if (to.path === '/login') {
    if (token && to.query && to.query.redirect) {
      return next(to.query.redirect as string)
    } else {
      return next()
    }
  } else {
    if (to.matched.length === 0) {
      next("/error")
    }
  }

  if (!to.meta.requireAuth) {
    next()
  } else {
    if (token) {
      if (to.matched.length === 0) {
        next("/error")
      }
      next()
    } else {
      if (to.matched.length === 0) {
        next("/error")
      } else if (code) {
        next()
      } else {
        next({
          path: "login",
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  }
})





export default router
